export default function HashCode(this: any): number {
    if (typeof this == 'undefined') {
        throw new Error('no objects have been bound to HashCode.');
    } else if (typeof this == 'symbol') {
        throw new Error('symbols cannot be bound to HashCode.');
    }

    if (this == null) {
        return 0;
    }

    let hash = 0;

    const str: string = typeof this == 'object'
        ? Object.entries(this).filter(([k, v]) => typeof k != 'symbol' && typeof v != 'symbol').join('')
        : this.toString();

    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash = hash & hash;
    }

    return Math.abs(hash);
}
